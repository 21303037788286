import Head from 'next/head';

function Site() {
  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
        <title>Google Photos for Desktop</title>
      </Head>

      <div className="Container">
        <div className="About">
          <h1 className="About__title">Google Photos for Desktop</h1>
          <p className="About__text">Free unofficial application for your Mac. Supports all features you know.</p>

          <img className="About__screen" src="/assets/image@2x.jpg" alt="Google Photos on Mac" />
        </div>
      </div>

      <div className="Container">
        <div className="Download">
          <a
            download
            href="https://google-desktop-update.herokuapp.com/download/osx"
            className="Download__button mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect mdl-button--accent"
            target="_blank"
            rel="noreferrer"
          >
            download for mac
          </a>

          <a
            href="https://twitter.com/intent/tweet?url=http%3A%2F%2Fgooglephotos.forcandies.com&via=workforcandies&text=I%20want%20this%20Google%20Photos%20app%20for%20Windows&hashtags=mac%2C%20googlephotos"
            className="Download__link"
            target="_blank"
            rel="noreferrer"
          >
            Looking for a Windows version? Tweet about it.
          </a>
        </div>
      </div>

      <div className="Footer">
        <div className="Container">
          <div className="Github">
            <a href="https://github.com/forCandies/googlePhotosDesktop" className="Github__textLink">
              Make us Stars on GitHub.
            </a>
            <a
              href="https://github.com/forCandies/googlePhotosDesktop"
              className="mdl-button mdl-js-button mdl-button--icon mdl-js-ripple-effect"
            >
              <span className="Github__icon">Github repository</span>
            </a>
          </div>
          <div className="Author">
            <a href="https://forcandies.com/" target="_blank" className="Author__iconLink" rel="noreferrer">
              made forCandies
            </a>
          </div>
        </div>
      </div>
    </>
  );
}

export default Site;
